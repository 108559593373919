import React from "react"
import Layout from "../components/layout"

import * as aboutStyles from "../styles/pages/about.module.scss"
import Head from "../components/head"

const AboutPage = () => {
  return (
    <Layout>
      <Head title="About" />
      <h1 className={aboutStyles.heading}>About Me</h1>
      <p>
        I’m Connor, an iOS app and Website developer based in Newcastle upon
        Tyne. I take absolute pride in creating products that are as fast,
        beautiful, simple and scalable as I possibly can, with no compromises.
      </p>
      <br />
      <p>
        I’ve always been on the tech side of things, and ever since I built my
        first Bebo site, I was hooked… a bit of a blast from the past, I know.
      </p>
      <br />
      <p>
        My motive behind going freelance is due to beginning my own company,
        Venyoo. I’m lucky because I know, or am willing to learn a lot of the
        required skills to help a start up build their technology stacks, be
        that an app or a website. It dawned on me though, how difficult it must
        be for the average director! You know, expert in their own domain, but
        not so much of an expert at the tech, maybe they don’t have the time to
        learn, or simply don’t even know who to ask, or where to get started…
        that is my aim. I want to make this process easier.
      </p>
      <br />
      <p>
        I will always be approachable and work with you collaboratively in order
        to best portray your companies image and values. I will always give you
        my honest opinion, in the best interest of your company. I will always
        strive to make your companies digital presence stand out above your
        competitors.
      </p>
      <br />
      <p>
        I’d love to know what project ideas you have, no matter how small, or
        large. Don’t hesitate to contact me, even for a simple discussion… I’d
        be glad to help in any way I can!
      </p>
    </Layout>
  )
}

export default AboutPage
